import React, { useState, useEffect } from 'react';
import './Cloth.scss';
import __ from './../utils/translation';
import $ from "jquery";

import IconColth1 from '../cloth_fabric/thumbs/1.jpg';
import IconColth2 from '../cloth_fabric/thumbs/2.jpg';
import IconColth3 from '../cloth_fabric/thumbs/3.jpg';
import IconColth4 from '../cloth_fabric/thumbs/4.jpg';
import IconColth5 from '../cloth_fabric/thumbs/5.jpg';
import IconColth6 from '../cloth_fabric/thumbs/6.jpg';
import IconColth7 from '../cloth_fabric/thumbs/7.jpg';
import IconColth8 from '../cloth_fabric/thumbs/8.jpg';
import IconColth9 from '../cloth_fabric/thumbs/9.jpg';
import IconColth10 from '../cloth_fabric/thumbs/10.jpg';

import IconColth11 from '../cloth_fabric/thumbs/11.jpg';
import IconColth12 from '../cloth_fabric/thumbs/12.jpg';
import IconColth13 from '../cloth_fabric/thumbs/13.jpg';
import IconColth14 from '../cloth_fabric/thumbs/14.jpg';
import IconColth15 from '../cloth_fabric/thumbs/15.jpg';
import IconColth16 from '../cloth_fabric/thumbs/16.jpg';
import IconColth17 from '../cloth_fabric/thumbs/17.jpg';
import IconColth18 from '../cloth_fabric/thumbs/18.jpg';
import IconColth19 from '../cloth_fabric/thumbs/19.jpg';
import IconColth20 from '../cloth_fabric/thumbs/20.jpg';

import IconColth21 from '../cloth_fabric/thumbs/21.jpg';
import IconColth22 from '../cloth_fabric/thumbs/22.jpg';
import IconColth23 from '../cloth_fabric/thumbs/23.jpg';
import IconColth24 from '../cloth_fabric/thumbs/24.jpg';
import IconColth25 from '../cloth_fabric/thumbs/25.jpg';
import IconColth26 from '../cloth_fabric/thumbs/26.jpg';
import IconColth27 from '../cloth_fabric/thumbs/27.jpg';
import IconColth28 from '../cloth_fabric/thumbs/28.jpg';
import IconColth29 from '../cloth_fabric/thumbs/29.jpg';
import IconColth30 from '../cloth_fabric/thumbs/30.jpg';
import IconColth31 from '../cloth_fabric/thumbs/31.jpg';
import IconColth32 from '../cloth_fabric/thumbs/32.jpg';
import IconColth33 from '../cloth_fabric/thumbs/33.jpg';

import { Tabs, Tab } from './Tabs';
import { ChromePicker } from 'react-color';

const Cloth = ({}) => {

    const [drawSettings, setDrawSettings] = useState({
        duration: 1,
        time: 10,
        brilho: 0,
        metal: 0,
        scale: 0,
        color: 'rgba(255, 255, 255, 0)',
        camisa: true,
        short: true,
        meia: true,
        corpo: false,
        sapato: true,
        cabelo: true,
        mascara: true,
        oculos: true,
        hats: true,
        fone: true,
        polchete: true,
        morph: true,
        map: null
    })

    $("body").on('click', '.custom-type', function () {
        $(".custom-type").css("text-decoration", "none");
        $(this).css("text-decoration", "underline");

        $(".custom").css("display", "none");
        $("#" + $(this).data("type")).css("display", "block");

        if($(this).data("type") == "material"){
            $(".block-panel").css("opacity", "1");
        }else{
            $(".block-panel").css("opacity", "0.5");
        }
    });

    const handleBrilhoChange = (value) => {
        setDrawSettings({...drawSettings,
          brilho: value
        })
    
        window['updateRoughness'](value);
    }

    const handleMetalChange = (value) => {
        setDrawSettings({...drawSettings,
          metal: value
        })
    
        window['updateMetalness'](value);
    }

    const handleMapChange = (value) => {

        let reader = new FileReader()

        // handle image, read file, add to canvas
        /*reader.onload = (f) => {
            //f.target.result
            window['updateMap'](f.target.result);
            $("#map-mesh").val(''); 
            //$("#image-holder").append("<div data-type='"+file.type+"' class='img-holder' data-value='"+f.target.result+"' style='background-image: url("+f.target.result+")'><div class='remove_img_holder'>X</div><div class='crop_img_holder'>C</div></div>")
        }*/

        //reader.readAsDataURL(value.files[0])

        var fileName = URL.createObjectURL(value.files[0]);
        window['updateMap'](fileName);
        $("#map-mesh").val('');
        
        /*setDrawSettings({...drawSettings,
          metal: value
        })
    
        window['updateMetalness'](value);*/
    }

    const handleColorChange = (color) => {
        setDrawSettings({...drawSettings, color: `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`})
        window['updateColorMaterial'](color);
    }

    window['colorMesh'] = function(color, metal, brilho){
        setDrawSettings({...drawSettings, color: `rgba(${color.r * 255}, ${color.g * 255}, ${color.b * 255}, 1)`, metal: metal, brilho: brilho})
    }
    

    return (
        <>
            <p className="title lang" data-en="CUSTOMIZE MATERIALS" data-pt="PERSONALIZAR MATERIAIS" data-es="PERSONALIZAR MATERIALES">{__('CUSTOMIZE MATERIALS')}</p>

            <div className='options'>
                <p id="custom-material" data-type="material" data-display="block" className="subtitle custom-type">{__('Material')}</p>
                <p id="custom-parts" data-type="fabric-options-parent" data-display="block"  className="subtitle custom-type lang" data-en="Fabric" data-pt="Tecido" data-es="Tela">{__('Fabric')}</p>
            </div>

            <div className="block-panel options">
                <span className='lang' data-en="To edit the material, double click on the part you want to select" data-pt="Para editar o material, clique duas vezes na peça que deseja selecionar" data-es="Para editar el material, haga doble clic en la parte que desea seleccionar">To edit the material, double click on the part you want to select</span>
                <button id="object-name">...</button>
            </div>

            <div id="material" className='custom'>
                <div className='thumb-background-color material' id="color-mesh">
                    <Tab title={__('Color fill')} name="color">
                        <ChromePicker width="100%" color={drawSettings.color} onChange={handleColorChange} />
                    </Tab>
                </div>
                <div className="setting material">
                    <div className="label lang" data-en="Shininess" data-pt="Brilho" data-es="Brillo">{__('Shininess')}</div><div className="function">
                    <input id="brilho-mesh" type="range" min="0" max="100" steps="1"
                        onChange={ (e) => handleBrilhoChange(e.target.value) }
                        value={ drawSettings.brilho } />
                    </div>
                </div>
                <div className="setting material">
                    <div className="label lang" data-en="Metalness" data-pt="Metalidade" data-es="metalidad">{__('Metalness')}</div><div className="function">
                    <input id="metal-mesh" type="range" min="0" max="100" steps="1"
                        onChange={ (e) => handleMetalChange(e.target.value) }
                        value={ drawSettings.metal } />
                    </div>
                </div>

                <div className="setting material">
                    <div className="label lang" data-en="Custom Map" data-pt="Mapa personalizado" data-es="Mapa personalizado">{__('Custom Map')}</div><div className="function">
                    <input id="map-mesh" type="file" accept='.jpg'
                        onChange={ (e) => handleMapChange(e.target) }
                    />
                    </div>
                </div>
            </div>

            <div id="fabric-options-parent" className='custom'>
                <div className='options'>
                    <button id="add-fabric" className='lang' data-en="Add Texture" data-pt="Adicionar Textura" data-es="Agregar textura">Add Texture</button>
                    <button id="remove-fabric" className='lang' data-en="Remove Texture" data-pt="Remover Textura" data-es="Quitar textura">Remove Texture</button>
                </div>

                {/**<div className='options'>
                    <div>
                        <span>Repeat: </span>
                        <input step={1} min={1} max={100} id="input-repeat-fabric" type="number" defaultValue={1}></input>
                        <button id="update-fabric">Update</button>
                    </div>
                </div> */}

                <div id="fabric-options">
                    <div className='' id="tecido">
                        <div data-repeat="1" className="tecido" data-nam="0"></div>
                        <div data-repeat="1" style={{backgroundImage:  "url(" + IconColth1 + ")"}} className="tecido" data-nam="1"></div>
                        <div data-repeat="1" style={{backgroundImage:  "url(" + IconColth2 + ")"}} className="tecido" data-nam="2"></div>
                        <div data-repeat="1" style={{backgroundImage:  "url(" + IconColth3 + ")"}} className="tecido" data-nam="3"></div>
                        <div data-repeat="1" data-opacity="1" style={{backgroundImage:  "url(" + IconColth4 + ")"}} className="tecido" data-nam="4"></div>
                        <div data-repeat="1" style={{backgroundImage:  "url(" + IconColth5 + ")"}} className="tecido" data-nam="5"></div>
                        <div data-repeat="1" style={{backgroundImage:  "url(" + IconColth6 + ")"}} className="tecido" data-nam="6"></div>
                        <div data-repeat="1" style={{backgroundImage:  "url(" + IconColth7 + ")"}} className="tecido" data-nam="7"></div>
                        <div data-repeat="1" data-opacity="1" style={{backgroundImage:  "url(" + IconColth8 + ")"}} className="tecido" data-nam="8"></div>
                        <div data-repeat="1" style={{backgroundImage:  "url(" + IconColth9 + ")"}} className="tecido" data-nam="9"></div>
                        <div data-repeat="1" style={{backgroundImage:  "url(" + IconColth10 + ")"}} className="tecido" data-nam="10"></div>
                    
                        <div data-repeat="1" data-opacity="1" style={{backgroundImage:  "url(" + IconColth11 + ")"}} className="tecido" data-nam="11"></div>
                        <div data-repeat="1" style={{backgroundImage:  "url(" + IconColth12 + ")"}} className="tecido" data-nam="12"></div>
                        <div data-repeat="1" style={{backgroundImage:  "url(" + IconColth13 + ")"}} className="tecido" data-nam="13"></div>
                        <div data-repeat="1" style={{backgroundImage:  "url(" + IconColth14 + ")"}} className="tecido" data-nam="14"></div>
                        <div data-repeat="1" style={{backgroundImage:  "url(" + IconColth15 + ")"}} className="tecido" data-nam="15"></div>
                        <div data-repeat="1" style={{backgroundImage:  "url(" + IconColth16 + ")"}} className="tecido" data-nam="16"></div>
                        <div data-repeat="1" style={{backgroundImage:  "url(" + IconColth17 + ")"}} className="tecido" data-nam="17"></div>
                        <div data-repeat="1" style={{backgroundImage:  "url(" + IconColth18 + ")"}} className="tecido" data-nam="18"></div>
                        <div data-repeat="1" style={{backgroundImage:  "url(" + IconColth19 + ")"}} className="tecido" data-nam="19"></div>
                        <div data-repeat="1" style={{backgroundImage:  "url(" + IconColth20 + ")"}} className="tecido" data-nam="20"></div>
                    
                        <div data-repeat="1" style={{backgroundImage:  "url(" + IconColth21 + ")"}} className="tecido" data-nam="21"></div>
                        <div data-repeat="1" style={{backgroundImage:  "url(" + IconColth22 + ")"}} className="tecido" data-nam="22"></div>
                        <div data-repeat="1" style={{backgroundImage:  "url(" + IconColth23 + ")"}} className="tecido" data-nam="23"></div>
                        <div data-repeat="1" style={{backgroundImage:  "url(" + IconColth24 + ")"}} className="tecido" data-nam="24"></div>
                        <div data-repeat="1" style={{backgroundImage:  "url(" + IconColth25 + ")"}} className="tecido" data-nam="25"></div>
                        <div data-repeat="1" style={{backgroundImage:  "url(" + IconColth26 + ")"}} className="tecido" data-nam="26"></div>
                        <div data-repeat="1" style={{backgroundImage:  "url(" + IconColth27 + ")"}} className="tecido" data-nam="27"></div>
                        <div data-repeat="1" style={{backgroundImage:  "url(" + IconColth28 + ")"}} className="tecido" data-nam="28"></div>
                        <div data-repeat="1" data-opacity="1" style={{backgroundImage:  "url(" + IconColth29 + ")"}} className="tecido" data-nam="29"></div>
                        <div data-repeat="1" data-opacity="1" style={{backgroundImage:  "url(" + IconColth30 + ")"}} className="tecido" data-nam="30"></div>

                        <div data-repeat="1" style={{backgroundImage:  "url(" + IconColth31 + ")"}} className="tecido" data-nam="31"></div>
                        <div data-repeat="1" data-opacity="1" style={{backgroundImage:  "url(" + IconColth32 + ")"}} className="tecido" data-nam="32"></div>
                        <div data-repeat="1" style={{backgroundImage:  "url(" + IconColth33 + ")"}} className="tecido" data-nam="33"></div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Cloth