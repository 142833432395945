/*
 * SVG Editor
 * version: 1.0.1
 *
 */
/* eslint-disable */
import React, { useState, useEffect } from 'react';
import Notification from './components/Notification';
import Header from './components/Header';
import Button from './components/Button';
import FloatingMenu from './components/FloatingMenu';
import Menu from './components/Menu';
import Toolbar from './components/Toolbar';
import FabricCanvas from './components/FabricCanvas';
import ToolPanel from './components/ToolPanel';
import SelectionSettings from './components/SelectionSettings';
import CanvasSettings from './components/CanvasSettings';
import DrawSettings from './components/DrawSettings';
import Shapes from './components/Shapes';
import UploadSettings from './components/UploadSettings';
import Layers from './components/Layers';

//RAFAEL
import BackgroundScene from './components/BackgroundScene';
import Music from './components/Music';
import Transitions from './components/Transitions';
import ChangeSport from './components/ChangeSport';
import UploadCustom from './components/UploadCustom';
import Video from './components/Video';
import Animation from './components/Animation';
import Material from './components/Material';
import Download from './components/Download';
import Annotation from './components/Annotation';
import Clone from './components/Clone';
import AnimationState from './components/AnimationState';
import FpsState from './components/FpsState';
import TextState from './components/TextState';
import ObjState from './components/ObjState';
import RenderState from './components/RenderState';

import LightState from './components/LightState';
import Cloth from './components/Cloth';
import Patterns from './components/Patterns';
import { InputSelect, Option } from './components/InputSelect';

import __ from './utils/translation';
import saveInBrowser from './utils/saveInBrowser';
import { downloadImage, downloadSVG } from './utils/downloadImage';
import { undo, redo } from './utils/undoRedo';
import { editorTips } from './utils/editorTips';
import { handleDrawingModes } from './utils/handleDrawingModes';
import { applyZoom, zoomWithKeys, zoomWithMouse, zoomOptions } from './utils/zoom';

import logo from './images/logo.png';
import { ReactComponent as IconGear } from './icons/gear.svg';
import { ReactComponent as IconUndo } from './icons/undo.svg';
import { ReactComponent as IconRedo } from './icons/redo.svg';
import { ReactComponent as IconTick } from './icons/tick.svg';
import { ReactComponent as IconDownload } from './icons/down.svg';
import { ReactComponent as IconClose } from './icons/close.svg';
import { ReactComponent as IconBrush } from './icons/brush.svg';
import { ReactComponent as IconCursor } from './icons/cursor.svg';
import { ReactComponent as IconLine } from './icons/line.svg';
import { ReactComponent as IconPath } from './icons/path.svg';
import { ReactComponent as IconShape } from './icons/shape.svg';
import { ReactComponent as IconText } from './icons/text.svg';
import { ReactComponent as IconUpload } from './icons/upload.svg';
import { ReactComponent as IconZoom } from './icons/zoom.svg';
import { ReactComponent as IconWallPaper } from './icons/wallpaper.svg';

import { ReactComponent as IconDownload2 } from './icons/file_download.svg';
import { ReactComponent as IconUpload2 } from './icons/file_upload.svg';
import { ReactComponent as IconAR } from './icons/view_in_ar.svg';

import { ReactComponent as IconStudio } from './icons/studio.svg';
import { ReactComponent as IconSports } from './icons/sports.svg';
import { ReactComponent as IconVideo } from './icons/video.svg';
import { ReactComponent as IconCloth } from './icons/cloth.svg';
import { ReactComponent as IconAnimation } from './icons/animation.svg';
import { ReactComponent as IconEdit } from './icons/edit.svg';
import { ReactComponent as IconStyler } from './icons/styler.svg';
import { ReactComponent as IconPlay } from './icons/play.svg';
import { ReactComponent as IconBoy } from './icons/boy.svg';
import { ReactComponent as IconCamera } from './icons/flip_camera_android_FILL0_wght400_GRAD0_opsz48.svg';
import { ReactComponent as IconBody } from './icons/metabolism_FILL0_wght400_GRAD0_opsz48.svg';
import { ReactComponent as IconAutoRenew } from './icons/autorenew.svg';
import { ReactComponent as IconArrowBack } from './icons/arrow_back_FILL0_wght400_GRAD0_opsz24.svg';
import { ReactComponent as IconGraph } from './icons/account_tree_FILL0_wght400_GRAD0_opsz24.svg';
import { ReactComponent as IconLogOut } from './icons/power_settings_new_FILL0_wght400_GRAD0_opsz48.svg';
import { ReactComponent as IconSave } from './icons/save_FILL0_wght400_GRAD0_opsz48.svg';
import { ReactComponent as IconFolder } from './icons/folder_FILL0_wght400_GRAD0_opsz48.svg';
import { ReactComponent as IconColor } from './icons/palette_FILL0_wght400_GRAD0_opsz48.svg';
import { ReactComponent as IconList } from './icons/receipt_long_FILL0_wght400_GRAD0_opsz48.svg';
import { ReactComponent as IconAnnotation } from './icons/edit_note_FILL0_wght400_GRAD0_opsz48.svg';
import { ReactComponent as IconColorPattern } from './icons/format_color_fill_FILL0_wght400_GRAD0_opsz48.svg';
import { ReactComponent as IconPattenrs } from './icons/view_kanban_FILL0_wght400_GRAD0_opsz48.svg';
import { ReactComponent as IconResolution } from './icons/6k_FILL0_wght400_GRAD0_opsz48.svg';
import { ReactComponent as IconWind } from './icons/air_FILL0_wght400_GRAD0_opsz48.svg';

import { ReactComponent as Brasil } from './icons/brazil-.png';

import { useSignOut } from "react-auth-kit";
import {useIsAuthenticated} from 'react-auth-kit';
import $ from "jquery";
import axios, { AxiosError } from "axios";

//
const Simulator = () => {

  window.canvasLoaded = false;

  const singOut = useSignOut();

  window['singOut'] = function () {
    singOut();
    window.open(window.location.origin, "_self");
    //window.close();
  }

  /*const logout = () => {
    singOut();
    window.open('https://cyberlusk.com/simulator/', "_self");
  };*/

  const isAuthenticated = useIsAuthenticated();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  var is_iframe = urlParams.get('i');
  
  if (!is_iframe) {
    import('./editor.js?version=2')
      .then((module) => {
        if(window.name == "not-logged" && isAuthenticated()){//
          window.name = "logged";
        }
    });
  }

  // states
  const [notification, setNotification] = useState({ message: null, type: null, seconds: null })
  const [downloadMenuVisible, setDownloadMenuVisible] = useState(false)
  const [activeTool, setActiveTool] = useState('select')

  const [canvas, setCanvas] = useState()
  const [loadSavedCanvas, setLoadSavedCanvas] = useState(true)
  const [activeSelection, setActiveSelection] = useState(null)
  const [history, setHistory] = useState({ index: null, states: [] })
  const [selectionInfo, setSelectionInfo] = useState(editorTips[Math.floor(Math.random() * editorTips.length)])
  const [zoom, setZoom] = useState(1)

  //--------------------------------------------------------------------


  // on start: check if there is a saved canvas in this browser and ask if we should load it
  useEffect(() => {
    if (canvas && loadSavedCanvas) {
      const savedCanvas = saveInBrowser.load('canvasEditor');
      if (savedCanvas && window.confirm( __('We found a project saved in this browser! Do you want to load it?') )) {
        canvas.loadFromJSON(savedCanvas, canvas.renderAll.bind(canvas));
      }

      setLoadSavedCanvas(false);
    }
  }, [canvas, loadSavedCanvas])


  //--------------------------------------------------------------------


  // on active selection update: change active tool to select
  useEffect(() => {
    if (!activeSelection) return

    setActiveTool('select')

    // scroll to top in tool panel
    document.querySelector('.toolpanel .holder').scrollTop = 0

    window["ApplyLanguage"]();
  }, [activeSelection])


  //--------------------------------------------------------------------


  // on active tool change: deselect all object, handle drawing modes
  useEffect(() => {
    if (!canvas) return

    //if (activeTool !== 'select') canvas.discardActiveObject().requestRenderAll()

    handleDrawingModes(canvas, activeTool, setSelectionInfo)
  }, [canvas, activeTool])


  //--------------------------------------------------------------------


  // save history and unsaved work alert
  const maxHistory = 10
  useEffect(() => {
    if (!canvas) return

    const saveHistory = () => {
      let updatedHistory = [...history.states]

      // if any action happens after undo, clear all (redo) actions after current state
      if (history.index < history.states.length - 1) updatedHistory.splice(history.index + 1)

      // add current state to history
      updatedHistory.push(canvas.toJSON(['lockMovementX', 'lockMovementY', 'lockRotation', 'lockScalingX', 'lockScalingY', 
      'hasControls', 'default', 'id', 'scaleX', 'scaleY']))
      if (updatedHistory.length > maxHistory) updatedHistory.shift()

      setHistory({ index: updatedHistory.length - 1, states: updatedHistory })
    }
    canvas.on('object:modified', saveHistory)
    canvas.on('path:created', saveHistory)


    const unsavedWorkAlert = (e) => {
      if (history.states.length > 1) e.returnValue = __(`Are you sure you want to leave?`)
    }
    window.addEventListener('beforeunload', unsavedWorkAlert)


    // cleanup
    return () => {
      canvas.off('object:modified', saveHistory)
      canvas.off('path:created', saveHistory)

      window.removeEventListener('beforeunload', unsavedWorkAlert)
    }
  }, [canvas, history])


  //--------------------------------------------------------------------


  // keyboard & mouse shortcuts
  useEffect(() => {
    if (!canvas) return

    // select tool (v)
    const keyV = (e) => {
      const key = e.which || e.keyCode;
      if (key === 86 && document.querySelectorAll('textarea:focus, input:focus').length === 0) {
        //setActiveTool('select')
      }
    }
    document.addEventListener('keydown', keyV)


    // undo/redo (ctrl z/y)
    const ctrZY = (e) => {
      const key = e.which || e.keyCode;

      if (key === 90 && e.ctrlKey && document.querySelectorAll('textarea:focus, input:focus').length === 0) {
        undo(canvas, history, setHistory)
      }

      if (key === 89 && e.ctrlKey && document.querySelectorAll('textarea:focus, input:focus').length === 0) {
        redo(canvas, history, setHistory)
      }
    }
    document.addEventListener('keydown', ctrZY)


    // zoom out/in/reset (ctr + -/+/0)
    const keyZoom = (e) => zoomWithKeys(e, canvas, setZoom, applyZoom)
    document.addEventListener('keydown', keyZoom)


    // zoom out/in with mouse
    const mouseZoom = (e) => zoomWithMouse(e, canvas, setZoom, applyZoom)
    document.addEventListener('wheel', mouseZoom, { passive: false })


    // cleanup
    return () => {
      document.removeEventListener('keydown', keyV)
      document.removeEventListener('keydown', ctrZY)
      document.removeEventListener('keydown', keyZoom)
      document.removeEventListener('wheel', mouseZoom)
    }
  }, [canvas, history])

  //--------------------------------------------------------------------

  const [drawSettings, setDrawSettings] = useState({
    duration: 1,
    time: 10,
    brilho: 0,
    metal: 0,
    scale: 0,
    color: 'rgba(255, 255, 255, 0)',
    camisa: true,
    short: true,
    meia: true,
    corpo: false,
    sapato: false,
    cabelo: false,
    mascara: false,
    oculos: false,
    hats: false,
    fone: false,
    polchete: false,
    morph: true,
})

  const handleBodyState = (attribute, value) => {
    setDrawSettings({...drawSettings,
        attribute: value
    })

    window['stateBody'](attribute, value);
  }

  const handleMorphState = (value) => {
      setDrawSettings({...drawSettings,
          attribute: value
      })

      window['stateMorph'](value);
  }

  // render layout
  return (
    <div id="app" className='configurator-main'>

      <div id="obj-options-parent">

        <button id="expand-obj-options">
          <i className="fas fa-chevron-down"></i>
        </button>

        <div className='expand states' data-id="corpo" >
          <span>Corpo</span>
          <input id="state-main-body" type="checkbox" value="false" defaultChecked={drawSettings.corpo}
                        onChange={(e) => handleBodyState('corpo', e.target.checked ) } />
        </div>

        {/**<div className='expand states' data-id="camisa">
          <span>Camisa</span>
          <input type="checkbox" value="true" defaultChecked={drawSettings.camisa}
                        onChange={(e) => handleBodyState('camisa', e.target.checked ) } />
        </div>
        <div className='expand states' data-id="short">
            <span>Short</span>
            <input type="checkbox" value="true" defaultChecked={drawSettings.short}
                        onChange={(e) => handleBodyState('short', e.target.checked ) } />
        </div> */}
        {/**<div className='expand states' data-id="corpo" >
          <span>Body</span>
          <input id="state-main-body" type="checkbox" value="false" defaultChecked={drawSettings.corpo}
                        onChange={(e) => handleBodyState('corpo', e.target.checked ) } />
        </div>
        <div className='expand states' data-id="morph" >
          <span>Facial Expressions</span>
          <input id="state-main-expressions" type="checkbox" value="true" defaultChecked={drawSettings.morph}
                        onChange={(e) => handleMorphState(e.target.checked ) } />
        </div> */}
        {/**<div className='expand states' data-id="meia">
          <span>Meias</span>
          <input id="state-main-socks" type="checkbox" value="true" defaultChecked={drawSettings.meia}
                        onChange={(e) => handleBodyState('meia', e.target.checked ) } />
        </div> */}
        <div className='expand states' data-id="shoes" data-opened="false">
          <span>Sapato</span>
          <input id="state-main-shoes" type="checkbox" value="false" defaultChecked={drawSettings.sapato}
                        onChange={(e) => handleBodyState('sapato', e.target.checked ) } />
        </div>

        {/**<div className='expand states' data-id="hair" data-opened="false">
          <span>Cabelos</span>
          <input id="state-main-hair" type="checkbox" value="false" defaultChecked={drawSettings.cabelo}
                        onChange={(e) => handleBodyState('cabelo', e.target.checked ) } />
        </div>

        <div className='expand masksP states' data-id="mask" data-opened="false">
          <span>Mascara</span>
          <input id="state-main-mask" type="checkbox" value="false" defaultChecked={drawSettings.mascara}
                        onChange={(e) => handleBodyState('mask', e.target.checked ) } />
        </div>
                
        <div className='expand glassesP states' data-id="glasses" data-opened="false">
          <span>Óculos</span>
          <input id="state-main-glasses" type="checkbox" value="false" defaultChecked={drawSettings.oculos}
                        onChange={(e) => handleBodyState('oculos', e.target.checked ) } />
        </div>

        <div className='expand states' data-id="hats" data-opened="false">
          <span>Chapéus</span>
          <input id="state-main-hat" type="checkbox" value="false" defaultChecked={drawSettings.hats}
                        onChange={(e) => handleBodyState('hats', e.target.checked ) } />
        </div> */}
      </div>

      <div id="tutorial-panel">
        <div id="tutorial-header">
          <span id="title-tutorial">TUTORIAL</span>
          <div id="close-tutorial">
            <button>VOLTAR</button>
          </div>
        </div>
        
        <div id="tutorial-body">
          <div id="tutorial-left">
            <span className='active' data-link="1">1 - Login, logout, idioma, tela cheia e controles</span>
            <span data-link="2">2 - Mudar esportes</span>
            <span data-link="3">3 - Mudar fundo</span>
            <span data-link="4">4 - Download</span>
            <span data-link="5">5 - Anotação</span>
            <span data-link="6">6 - Apresentação</span>
            <span data-link="7">7 - Lista de pedidos</span>
            <span data-link="8">8 - Material, textura, resolução e  qualidade</span>
            <span data-link="9">9 - Customizar avatar</span>
            <span data-link="10">10 - Animações e poses</span>
            <span data-link="11">11 - Salvar, editar e buscar projeto</span>
            <span data-link="12">12 - Svg-editor parte 1</span>
            <span data-link="13">13 - Svg-editor parte 2</span>
            <span data-link="14">14 - Upload Customizado</span>
            <span data-link="15">15 - Upload cenário</span>
            <span data-link="16">16 - Iframe parte 1</span>
            <span data-link="17">17 - Iframe parte 2</span>
            <span data-link="18">18 - Realidade aumentada</span>
            <span data-link="19">19 - Projetos vindo do cliente pelo iframe</span>
            <span data-link="20">20 - Tabela de cores personalizada</span>
          </div>
          <div id="tutorial-right">
            <div id="tutorial-video">
            <iframe src="https://www.youtube.com/embed/tgbNymZ7vqY">
            </iframe>
            </div>
            <div id="tutorial-info">
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
            </div>
          </div>
        </div>
      </div>

      <div id="custom-banner">
        <div id="custom-banner-box">
          <div id="custom-banner-box-input">
            <label className='banner-rec' htmlFor="width-banner">Banner Width (cm)</label>
            <input className='banner-rec' id="width-banner" name="width-banner" type='number' defaultValue="100"></input>
            <label className='banner-rec' htmlFor="height-banner">Banner Height (cm)</label>
            <input className='banner-rec' id="height-banner" name="height-banner" type='number' defaultValue="100"></input>
            <label className='banner-rad' htmlFor="radius-banner">Banner Radius (cm)</label>
            <input className='banner-rad' id="raidus-banner" name="raidus-banner" type='number' defaultValue="100"></input>
          </div>
          <div id="custom-banner-box-buttons">
            <button id="close-custom-banner">Cancel</button>
            <button id="confirm-custom-banner">Confirm</button>
          </div>
        </div>
      </div>

      

      <div id='configurator2' className='toolbar-buttons'>
          <div className="panel-custom-buttons">

            {/**<button className='svg' id="update-canvas-3d" title='UPDATE 3D'>
              <i className="svg fas fa-sync-alt fa-2x"></i>
            </button> */}

            {/**<button className='svg' id="expand-canvas" title='HIDE/SHOW CANVAS'>
              <i id="arrow-canvas-right" className="fas fa-arrow-right fa-2x"></i>
              <i id="arrow-canvas-left" className="fas fa-arrow-left fa-2x"></i>
            </button> */}

            {/**<button id="state-wind" title={__('Wind')}><i className="fas fa-wind fa-2x"></i></button> */}

            {/**<button className='' id="update-canvas-3d" title='UPDATE 3D'>
              <IconResolution  />
            </button> */}

            {/**<button className='' id="update-material-3d" title='CHANGE MATERIAL'>
              <i className="fas fa-magic fa-2x"></i>
            </button>

            <button className='svg' id="edit-background" title="CHANGE CANVAS">
              <i className="fas fa-retweet fa-2x"></i>
            </button> */}

            <button className='svg' title='UNDO'>
              <i className="fas fa-history fa-2x"></i>
            </button>
            
            <button className='svg' title='REDO'>
              <i className="fas fa-history fa-2x redo"></i>
            </button>

            <button className='svg' id="canvas-default-size" title='RESET CANVAS ZOOM'>
              <i className="fas fa-search fa-2x"></i>
            </button>
            
            <button className='svg' id="toggle-grid" title='TOGGLE GRID'>
              <i className="fas fa-border-none fa-2x"></i>
            </button>
            
            <button title='FULL SCREEN' id="full_button">
              <i className="fas fa-expand fa-2x"></i>
            </button>

            {/**<button title='TUTORIAL' id="tutorial-start">
              <i className="fas fa-info fa-2x"></i>
            </button> */}

            <button id="registerColors" data-en='MY COLOR PALLET' data-pt='MINHA PALETA DE CORES' data-es="MI PALETA DE COLORES" data-panel="panel-color">
              <i className="fas fa-palette fa-2x"></i>
            </button>
            
            <button className='open-list main' title="MY WISH LIST" data-panel="panel-list">
              <i className="fas fa-clipboard-list fa-2x"></i>
            </button>
            
            <button className='main' id="open-folder" data-en='MY SAVED PROJECTS' data-pt='MEUS PROJETOS SALVOS' data-es="MIS PROYECTOS GUARDADOS" data-panel="panel-projects">
              <i className="far fa-folder fa-2x" id="open-folder"></i>
            </button>
            
            {/**<button className='main' id="open-panel-clients-projects" title='CLIENT PROJECTS' data-panel="panel-projects-clients">
              <i className="fas fa-users fa-2x"></i>
            </button> */}
            
            <button className='main' id="btn-log-out" title='LOGOUT'>
              <i className="fas fa-power-off fa-2x"></i>
            </button>
            

            {/**<div className='svg' id="line-svg-button"></div> */}
          </div>

          {/**<button className='main' id="btn-general-Options" title='LOGOUT'>
              <i className="fas fa-cog"></i>
          </button> */}

      </div>

      {/**<button className='button-editor' id="canvas-default-size">RESET CANVAS ZOOM</button>
          <button className='button-editor' id="update-canvas-3d">UPDATE 3D</button>
          <button className='button-editor' id="edit-background">EDIT BACKGROUND</button> 
          <button className='button-editor' id="registerColors">REGISTER COLORS</button> 
          <button className='button-editor' id="toggle-grid">TOGLE GRID</button>  */}

      <div id="panel-save">
        <div id="panel-box-save">
          <span id="storage-warning">Armazenamento excedido</span>
          <input type="text" id="input-save-name"></input>
          <div>
            <button id="btn-save-cache-confirm">Salvar</button>
            <button id="btn-close-save">Cancelar</button>
          </div>
        </div>
      </div>

      <div className='panel-default'>
  <div id="panel-search">
    <button id="close-search-panel" className='lang' data-en="BACK" data-pt="VOLTAR" data-es="ATRÁS">BACK</button>
  </div>
  <div className='panel-default-header'>
    <span id="panel-title">TITLE</span>
    <div id="box-options-projects">
      <button className='panel panel-color lang' id="save-color"  data-en="SAVE COLOR PALETTE" data-pt="SALVAR PALETA DE CORES" data-es="GUARDAR PALETA DE COLORES">SAVE COLOR TABLE</button>

      <button className='panel panel-list lang' id="save-list" data-en="SAVE" data-pt="SALVAR" data-es="SALVAR">SAVE</button>
      <button className='panel panel-list' id="download-csv">DOWNLOAD CSV</button>

      {/*<div className="search-bar panel panel-projects">
        <input id="search-text" type="text" placeholder="Search by name.." name="search"></input>
        <button type="submit" id="search-project"><i className="fa fa-search"></i></button>
        <select id="search-options">
          <optgroup label="Gender">
            <option value="male">Man</option>
            <option value="female">Woman</option>
          </optgroup>
          <optgroup label="Type">
            <option value="vest">Vests</option>
            <option value="moto">Motorcycles</option>
            <option value="banner">Banners</option>
            <option value="others">Others</option>
            <option value="dummy">Main</option>
          </optgroup>
          <optgroup id="option-specific" label="Specific">
          </optgroup>
        </select>
      </div>*/}

      <button className='panel panel-projects' id="title-mb">0 MB / 50 MB</button>
      {/*<button className='panel panel-projects' id="btn-categories">GERENCIAR</button>*/}
      <button className='panel panel-projects lang' id="btn-save-cache" data-en="SAVE" data-pt="SALVAR" data-es="SALVAR">SAVE</button>
      <button className='panel panel-projects lang' id="btn-upload-obj-client" data-en="REGISTER" data-pt="REGISTRAR" data-es="REGISTRO">REGISTER</button>
      <button className='panel panel-projects lang' id="btn-edit-cache" data-en="EDIT" data-pt="EDITAR" data-es="EDITAR">EDIT</button>
      {/*<button className='panel panel-projects' id="clear-folder">CLEAR ALL</button>*/}
      
      <button id="close-panel" className='lang' data-en="BACK" data-pt="VOLTAR" data-es="ATRÁS">BACK</button>
      <button id="close-panel-start" className='lang' data-en="BACK" data-pt="VOLTAR" data-es="ATRÁS">BACK</button>
    </div>
  </div>
  <div className='panel panel-projects' id="my-projects-pages"></div>
  <div className='panel-default-body'>
    <div id="lista-pedidos" className='panel panel-list'>
      <div>
        <span>Name</span>
        <input type="text" id="list-name" />
      </div>
      <div>
        <span>Number</span>
        <input type="number" id="list-number" step="1" />
      </div>
      <div>
        <span>Size</span>
        <input type="number" id="list-size" step="0.1" />
      </div>
      <div>
        <span>Quantity</span>
        <input type="number" id="list-qtd" step="1" />
      </div>
      <div>
        <button id="list-add">ADD</button>
      </div>
    </div>
    <div id="lista-geral" className='panel panel-list'>
      <div>
        <div className="list-block">
          <span>NAME</span>
        </div>
        <div className="list-block">
          <span>NUMBER</span>
        </div>
        <div className="list-block">
          <span>SIZE</span>
        </div>
        <div className="list-block">
          <span>QUANTITY</span>
        </div>
      </div>
    </div>
    <div className='panel panel-projects'>
      <div  id="panel-folder"></div>
    </div>
    <div className='panel panel-projects-clients'>
      <div id="panel-folder-clients"></div>
    </div>
    <div className='panel panel-color'>
      <div id="my-colors">
        <div id="my-colors-options">
          <div id="options-color">
            <span id="choose-rgb">RGB</span>
            <span id="choose-cmk">CMYK</span>
            <span id="choose-picker" className='lang' data-en="CHOOSE" data-pt="ESCOLHER" data-es="ELEGIR">CHOOSE</span>
          </div>
          <div>
            <div id="options-rgb">
              <label>R</label>
              <input id="my-color-r" min="0" max="255" step="1" type="number" defaultValue="0"></input>
              <label>G</label>
              <input id="my-color-g" min="0" max="255" step="1" type="number" defaultValue="0"></input>
              <label>B</label>
              <input id="my-color-b" min="0" max="255" step="1" type="number" defaultValue="0"></input>
            </div>
            <div id="options-cmk">
              <label>C</label>
              <input id="my-color-c" min="0" max="100" step="1" type="number" defaultValue="0"></input>
              <label>M</label>
              <input id="my-color-m" min="0" max="100" step="1" type="number" defaultValue="0"></input>
              <label>Y</label>
              <input id="my-color-y" min="0" max="100" step="1" type="number" defaultValue="0"></input>
              <label>K</label>
              <input id="my-color-k" min="0" max="100" step="1" type="number" defaultValue="0"></input>
            </div>
            <div id="options-picker">
              <input id="my-color-picker" type="color"></input>
            </div>
          </div>
          <div>
            <button id="add-color" className='lang' data-en="ADD COLOR" data-pt="ADICIONAR COR" data-es="AÑADIR COLOR">ADD COLOR</button>
            <button id="update-color" className='lang' data-en="UPDATE COLOR" data-pt="ATUALIZAR COR" data-es="ACTUALIZAR COLOR">UPDATE COLOR</button>
            <button id="remove-color" className='lang' data-en="REMOVE COLOR" data-pt="REMOVER COR" data-es="QUITAR COLOR">REMOVE COLOR</button>
          </div>
        </div>
        <section id="list-colors"></section>
      </div>
    </div>
  </div>
</div>

      <img id="holding_img" src="/image.svg"></img>

      <div id="svg-container"></div>

      <div id="configurator">

        <div id="warning">
          <div id="warning-panel">
            <span>AVISO</span>
            <div>
              <button id="btn-1">1</button>
              <button id="btn-2">2</button>
              <button id="btn-3">Cancelar</button>
            </div>
          </div>
        </div>

        <Toolbar activeTool={activeTool} id="toolbar-configurator">
          <button id="svg-editor-state"><IconEdit  /></button>
          <button data-title="ChangeSport" name="change_sport" title={__('ChangeSport')}><i className="icon-svg fas fa-tshirt"></i></button>
          <button data-title="BackgroundScene" name="background_scene" title={__('BackgroundScene')}><IconWallPaper /></button>
          <button data-title="UploadCustom" name="upload_custom" title={__('UploadCustom')}><IconUpload2 /></button> 
          <button data-title="Video" name="video" title={__('Video')}><IconVideo  /></button>
          <button data-title="Animation" id={__("animation-side-menu")} name="animation" title={__('Animation')}><IconPlay  /></button>
          {/**<button data-title="Material" id={__("material-side-menu")} name="material" title={__('Material')} ><IconBoy  /></button> */}
          <button id="cloth-button" data-title="Cloth" name="cloth" title={__('Cloth')} ><IconCloth /></button>
          <button data-title="Download" name="download" title={__('Download')} ><IconDownload2 /></button>

          <button data-title="Music" name="music" title={__('Music')}><i className="icon-svg fas fa-music"></i></button>
          <button data-title="Transitions" name="transitions" title={__('Transitions')}><i className="icon-svg fas fa-random"></i></button>
          {/**<button data-title="Annotation" name="annotation" title={__('Annotation')} ><IconAnnotation /></button> 

          <button id="clone-cloth" data-title="Clone" name="clone" title={__('Clone')} >
            <i className="fas fa-clone"></i>
          </button> */}

          <button id="anim-state" data-title="AnimationState" name="AnimationState" title={__('AnimationState')} >
            <i className="fas fa-play"></i>
          </button>

          {/**<button id="light-state" data-title="LightState" name="LightState" title={__('LightState')} >
            <i className="fas fa-lightbulb svg-font"></i>
          </button> */}

          {/**<button id="fps-state" data-title="FpsState" name="FpsState" title={__('FpsState')} >
            <i className="fas fa-chart-line svg-font"></i>
          </button> */}

          {/**<button id="text-state" data-title="TextState" name="TextState" title={__('TextState')} >
            <i className="fas fa-font svg-font"></i>
          </button> */}

          <button id="obj-state" data-title="ObjState" name="ObjState" title={__('ObjState')} >
            <i className="fas fa-male svg-font"></i>
          </button>

          {/**<button id="render-state" data-title="RenderState" name="RenderState" title={__('RenderState')} >
            <i className="fas fa-vector-square svg-font"></i>
          </button> */}

          <button id="reload-back"><IconArrowBack  /></button>
        </Toolbar>

        <ToolPanel visible={ activeSelection || (
            activeTool !== 'select' && activeTool !== 'line'  && activeTool !== 'path' && activeTool !== 'textbox'
          )}>

          <div className='toolpanel_panel' id="BackgroundScene">
            <BackgroundScene canvas={canvas} />
          </div>

          <div className='toolpanel_panel' id="Music">
            <Music canvas={canvas} />
          </div>

          <div className='toolpanel_panel' id="Transitions">
            <Transitions canvas={canvas} />
          </div>

          <div className='toolpanel_panel' id="ChangeSport">
            <ChangeSport canvas={canvas} />
          </div>

          <div className='toolpanel_panel' id="UploadCustom">
            <UploadCustom canvas={canvas} />
          </div>

          <div className='toolpanel_panel' id="Video">
            <Video canvas={canvas} />
          </div>

          <div className='toolpanel_panel' id="Animation">
            <Animation canvas={canvas} />
          </div>

          <div  className='toolpanel_panel' id="Material">
            <Material canvas={canvas} />
          </div>

          <div  className='toolpanel_panel' id="Cloth">
            <Cloth canvas={canvas} />
          </div>

          <div  className='toolpanel_panel' id="Download">
            <Download canvas={canvas} />
          </div>

          <div  className='toolpanel_panel' id="Annotation">
            <Annotation canvas={canvas} />
          </div>

          <div  className='toolpanel_panel' id="Clone">
            <Clone canvas={canvas} />
          </div>

          <div  className='toolpanel_panel' id="AnimationState">
            <AnimationState canvas={canvas} />
          </div>

          <div  className='toolpanel_panel' id="LightState">
            <LightState canvas={canvas} />
          </div>

          <div  className='toolpanel_panel' id="FpsState">
            <FpsState canvas={canvas} />
          </div>
          
          <div  className='toolpanel_panel' id="TextState">
            <TextState canvas={canvas} />
          </div>

          <div  className='toolpanel_panel' id="ObjState">
            <ObjState canvas={canvas} />
          </div>

          <div  className='toolpanel_panel' id="RenderState">
            <RenderState canvas={canvas} />
          </div>
          
          
        </ToolPanel>

      </div>

      <div id="svg-editor">

        <div id="panel-crop">
          <div className="image_container">
            <img id="blah" src="#" alt="your image" />
          </div>

          {/**Cropped image to display (only if u want) */}
          <div id="cropped_result"></div>  

          {/**<!-- // Will trigger crop event --> */}
           
          <div id="crop-buttons">
            <button id="crop_button">
              <i className="fas fa-cut"></i>
            </button>
            <button id="close_crop">
              <i className="fas fa-times"></i>
            </button>
          </div>
        </div>

        <div id="externalObject">
          <span>Double click on the mesh that you want to edit the map</span>
        </div>

        <Notification notification={notification} setNotification={setNotification} />


        {/**<Header> */}

          {/**<div className="input-select">
            <div className="select" id='select-map'>
              <div className="active-option" id="active-option-map">
              </div>
              <div className="list" id='list-maps'>
                <div className="option option-map">Square</div>
                <div className="option option-map">Round</div>
              </div>
            </div>
          </div> */}

          {/**<Button title={__('Undo')} handleClick={() => undo(canvas, history, setHistory)}
            className={ (!history.index || history.index === 0) ? 'disabled' : '' }><IconUndo /></Button>
          <Button title={__('Redo')} handleClick={() => redo(canvas, history, setHistory)}
            className={ (history.index < (history.states.length - 1)) ? '' : 'disabled' }><IconRedo /></Button> */}

          {/**<div className="separator"></div>

          <button id="update-canvas"><IconClose /></button> 

          <Button title={__('Save (to browser)')} handleClick={ () => {
              saveInBrowser.save('canvasEditor', canvas.toJSON());
              setNotification({ message: __('Project is saved in this browser!'), seconds: 3})
            } }><IconTick /></Button>*/}
          {/**<Button title={__('Download as..')} className="download" handleClick={ () => setDownloadMenuVisible(!downloadMenuVisible) }><IconDownload /></Button>
          <Button title={__('Close and open new')} handleClick={ () => {
              if (window.confirm(__('This will clear the canvas! Are you sure?'))) {
                setHistory({ index: null, states: [] }); canvas.clear(); saveInBrowser.remove('canvasEditor');
              }
            } } className="close"><IconClose /></Button> */}

          {/**<FloatingMenu visible={downloadMenuVisible} setVisible={setDownloadMenuVisible}>
            <Menu handleClick={ () => {
                setZoom(1);
                applyZoom(canvas, 1);
                setDownloadMenuVisible(false);
                downloadSVG(canvas.toSVG());
              } }>{__('Download as SVG')}</Menu>
            <Menu handleClick={ () => {
                setZoom(1);
                applyZoom(canvas, 1);
                setDownloadMenuVisible(false);
                downloadImage(canvas.toDataURL());
              } }>{__('Download as PNG')}</Menu>
            <Menu handleClick={ () => {
                setZoom(1);
                applyZoom(canvas, 1);
                setDownloadMenuVisible(false);
                downloadImage(canvas.toDataURL({ format: 'jpeg' }), 'jpg', 'image/jpeg');
              } }>{__('Download as JPG')}</Menu>
          </FloatingMenu>
        </Header> */}

        <div id="modal-text-iframe">
          <div className='box-modal-text-iframe'>
            <input id="txt-input-iframe" type='text'></input>
            <div className='box-buttons-modal-text-iframe'>
              <button id="close-modal-text-iframe" className='lang' data-en="CANCEL" data-pt="CANCELAR" data-es="CANCELAR">CANCEL</button>
              <button id="add-text-editor" className='lang' data-en="ADD" data-pt="ADICIONAR" data-es="PARA AÑADIR">ADD</button>
              {/**<button id="add-curved-text-editor">CURVO</button> */}
            </div>
          </div>
        </div>

        <Toolbar activeTool={activeTool}>
          <button id="svg-editor-state-back"><IconArrowBack  /></button>
          {/**<Button name="select" title={__('Select/move object (V)')} handleClick={ () => setActiveTool('select') }><IconCursor /></Button> */}
          <button data-title="layersParent" name="layers" title={__('layersParent')} onClick={ () => setActiveTool('layers') }><IconGraph /></button>
          <button data-title="Shapes" name="shapes" title={__('Shapes')} onClick={ () => setActiveTool('shapes') }><IconShape /></button>
          {/**<Button name="line" title={__('Line')} handleClick={ () => setActiveTool('line') }><IconLine /></Button>
          <Button name="path" title={__('Connectable lines & curves')} handleClick={ () => setActiveTool('path') }><IconPath /></Button>*/}
          <button data-title="" name="draw" title={__('Free draw')} onClick={ () => setActiveTool('draw') }><IconBrush /></button> 
          <button data-title="TextBox" name="textbox" title={__('TextBox')} onClick={ () => setActiveTool('textbox') }><IconText /></button>
          <button data-title="UploadImage" name="upload" title={__('UploadImage')} onClick={ () => setActiveTool('upload') }><IconUpload /></button>
          <button data-title="colorsParent" name="color" title={__('colorsParent')} onClick={ () => setActiveTool('color') }><IconColorPattern /></button>
          {/**<div className="separator"></div> 
          <Button name="background" title={__('Canvas options')} handleClick={ () => setActiveTool('background') }><IconGear /></Button>*/} 
          <button data-title="patternsParent" name="patternsParent" title={__('patternsParent')} onClick={ () => setActiveTool('patternsParent') }><IconPattenrs /></button> 
        </Toolbar>

        <ToolPanel visible={ activeSelection || (
            activeTool !== 'select' && activeTool !== 'line'  && activeTool !== 'path'
          )}>

          {activeTool === 'background' && !activeSelection && <CanvasSettings canvas={canvas} />}

          {activeTool === 'draw' && !activeSelection && <DrawSettings canvas={canvas} />}

          {activeTool === 'shapes' && !activeSelection && <Shapes canvas={canvas} />}

          {activeTool === 'layers' && !activeSelection && <Layers canvas={canvas} />}

          {/**{activeTool === 'layers' && !activeSelection && <Layers canvas={canvas} />} */}

          <div className='toolpanel_panel' id="UploadImage">
            <UploadSettings canvas={canvas} />
          </div>

          <div className='toolpanel_panel' id="TextBox">
            <p className="title lang" data-en="ADD TEXT" data-pt="ADICIONAR TEXTO" data-es="AÑADIR TEXTO">{__('ADD TEXT')}</p>
            <div>
              <p id="txt-text-editor" className='lang' data-en="click on the point in the editor where you want to add the text" data-pt="clique no ponto do editor onde deseja adicionar o texto" data-es="Haga clic en el punto del editor donde desea agregar el texto.">click on the point in the editor where you want to add the text</p>
              <button id="add-text-modal" className='lang' data-en="ADD NAME" data-pt="ADICIONAR NOME" data-es="AÑADIR NOMBRE">ADD NAME</button>
              <button id="add-number-modal" className='lang' data-en="ADD NUMBER" data-pt="ADICIONAR NÚMERO" data-es="AÑADE UN NÚMERO">ADD NUMBER</button>
              {/**<button id="add-text-editor">ADD TEXT</button>
              <button id="add-curved-text-editor">ADD CURVED TEXT</button> */}
            </div>
          </div>

          <div className='toolpanel_panel' id="colorsParent">
            <p className="title lang" data-en="COLOR OPTIONS" data-pt="OPÇÕES DE CORES" data-es="OPCIONES DE COLOR">{__('COLOR OPTIONS')}</p>
            <div id="colorsButtons">
              <button id="addcolorsvg" className='lang' data-en="Update colors" data-pt="Atualizar cores" data-es="Actualizar colores">Update colors</button>
              <button id="random-colors" className='lang' data-en="Random Colors" data-pt="Cores Aleatórias" data-es="Colores aleatorios">Random Colors</button>
            </div>
            <div id="colorsPanel">

            </div>
          </div>

          <div id="minimizer-svg-editor"></div>

          <div className='toolpanel_panel' id="patternsParent">
            <p className="title">{__('CLIPARTS')}</p>
            <div id="options-patterns"></div>
            <div id="patterns"></div>
          </div>

          {/**{activeTool === 'patterns' && !activeSelection && <Patterns canvas={canvas} />} */}

          {activeSelection && <SelectionSettings canvas={canvas} activeSelection={activeSelection} />}
        </ToolPanel>


        <FabricCanvas canvas={canvas} setCanvas={setCanvas}
          selectionInfo={selectionInfo} setSelectionInfo={setSelectionInfo}
          setActiveSelection={setActiveSelection}
          setHistory={setHistory} />


        <div className="bottom-info">
          <IconZoom />
          <select onChange={(e) => { setZoom(e.target.value); applyZoom(canvas, e.target.value); } } value={zoom}>
            {zoomOptions.map((z, index) => {
              if (index === 0 && !zoomOptions.includes(Number(zoom))) {
                return ( <option key={zoom} value="">{Number(zoom * 100).toFixed(0)}%</option> )
              }

              return (
                <option key={z} value={z}>{Number(z * 100).toFixed(0)}%</option>
              )
            })}
          </select>
        </div>
      </div>

      <div id="container">
        <div id="background-panel"></div>
      </div>

      <div id="main-ui">
        <div id="current-cloth">
          <span>MALE - SOCCER</span>
        </div>
        <div id="panel-stats"></div>
      </div>

      <div id="panel-categories">
        <div id="panel-categories-header">
          <button>Cadastrar Categorias</button>
          <button>Cadastrar Cliparts</button>
          <button>Cadastrar Objetos</button>
          <button>Voltar</button>
        </div>
        <div id="panel-categories-body">
            <div id="block-categories">

            </div>
            <div id="block-objects">

            </div>
            <div id="block-cliparts">

            </div>
        </div>
      </div>

      <div id="options-render">
        <button id="btn-close-render">Voltar</button>
        <button id="btn-download-render">Download</button>
      </div>

      <div id="all-download">
        <div id="all-download-panel">
          <button id="close-all-download">X</button>
          <div id="all-download-panel-check">
            <div className="checkboxes__item">
              <label className="checkbox style-e">
                <input type="checkbox" defaultChecked id="check-pdf"></input>
                <div className="checkbox__checkmark"></div>
                <div className="checkbox__body">PDF Resume</div>
              </label>
            </div>
            <div className="checkboxes__item">
              <label className="checkbox style-e">
                <input type="checkbox" defaultChecked id="check-vector-svg"></input>
                <div className="checkbox__checkmark"></div>
                <div className="checkbox__body">Vector SVG</div>
              </label>
            </div>
            <div className="checkboxes__item">
              <label className="checkbox style-e">
                <input type="checkbox" defaultChecked id="check-vector-pdf"></input>
                <div className="checkbox__checkmark"></div>
                <div className="checkbox__body">Vector PDF</div>
              </label>
            </div>
            <div className="checkboxes__item">
              <label className="checkbox style-e">
                <input type="checkbox" defaultChecked id="check-vector-jpg"></input>
                <div className="checkbox__checkmark"></div>
                <div className="checkbox__body">Vector JPG</div>
              </label>
            </div>
            <div className="checkboxes__item">
              <label className="checkbox style-e">
                <input type="checkbox" defaultChecked id="check-landscape"></input>
                <div className="checkbox__checkmark"></div>
                <div className="checkbox__body">Landscape</div>
              </label>
            </div>
            <div className="checkboxes__item">
              <label className="checkbox style-e">
                <input type="checkbox" defaultChecked id="check-portrait"></input>
                <div className="checkbox__checkmark"></div>
                <div className="checkbox__body">Portrait</div>
              </label>
            </div>
            <div className="checkboxes__item">
              <label className="checkbox style-e">
                <input type="checkbox" defaultChecked id="check-square"></input>
                <div className="checkbox__checkmark"></div>
                <div className="checkbox__body">Square</div>
              </label>
            </div>
            {/**<div className="checkboxes__item">
              <label className="checkbox style-e">
                <input type="checkbox" defaultChecked id="check-landscape-tra"></input>
                <div className="checkbox__checkmark"></div>
                <div className="checkbox__body">Transparent Landscape</div>
              </label>
            </div>
            <div className="checkboxes__item">
              <label className="checkbox style-e">
                <input type="checkbox" defaultChecked id="check-portrait-tra"></input>
                <div className="checkbox__checkmark"></div>
                <div className="checkbox__body">Transparent Portrait</div>
              </label>
            </div>
            <div className="checkboxes__item">
              <label className="checkbox style-e">
                <input type="checkbox" defaultChecked id="check-square-tra"></input>
                <div className="checkbox__checkmark"></div>
                <div className="checkbox__body">Transparent Square</div>
              </label>
            </div> */}

            <div className="checkboxes__item">
              <label className="checkbox style-e">
                <input type="checkbox" defaultChecked id="check-back"></input>
                <div className="checkbox__checkmark"></div>
                <div className="checkbox__body">Back</div>
              </label>
            </div>
            {/**<div className="checkboxes__item">
              <label className="checkbox style-e">
                <input type="checkbox" defaultChecked id="check-back-transparent"></input>
                <div className="checkbox__checkmark"></div>
                <div className="checkbox__body">Back Transparent</div>
              </label>
            </div> */}

            <div className="checkboxes__item">
              <label className="checkbox style-e">
                <input type="checkbox" defaultChecked id="check-fb"></input>
                <div className="checkbox__checkmark"></div>
                <div className="checkbox__body">Front and Back</div>
              </label>
            </div>
            {/**<div className="checkboxes__item">
              <label className="checkbox style-e">
                <input type="checkbox" defaultChecked id="check-fb-transparent"></input>
                <div className="checkbox__checkmark"></div>
                <div className="checkbox__body">Front and Back Transparent</div>
              </label>
            </div> */}

            <div className="checkboxes__item">
              <label className="checkbox style-e">
                <input type="checkbox" defaultChecked id="check-side"></input>
                <div className="checkbox__checkmark"></div>
                <div className="checkbox__body">Sides</div>
              </label>
            </div>
            {/**<div className="checkboxes__item">
              <label className="checkbox style-e">
                <input type="checkbox" defaultChecked id="check-side-transparent"></input>
                <div className="checkbox__checkmark"></div>
                <div className="checkbox__body">Sides Transparent</div>
              </label>
            </div> */}

            <div className="checkboxes__item">
              <label className="checkbox style-e">
                <input type="checkbox" defaultChecked id="check-video"></input>
                <div className="checkbox__checkmark"></div>
                <div className="checkbox__body">Video Rotate</div>
              </label>
            </div>
            <div className="checkboxes__item">
              <label className="checkbox style-e">
                <input type="checkbox" defaultChecked id="check-video-path"></input>
                <div className="checkbox__checkmark"></div>
                <div className="checkbox__body">Video Path</div>
              </label>
            </div>

          </div>

          <button id="download-all-btn">Download</button>
        </div>
      </div>

      <div id="loading">
        <div className="container2">
          <div id='loader2'>
            <div id='title2' className='flex2'>
              <p className='loading-text2'>LOADING</p>
              <div className='therefore2'>∴</div>
              <p className="loading-number2"></p>
            </div>
            <div id='loading-bar-border2'>
              <div className='loading-bar2'>
              </div>
            </div>
            <div id='warning2'>
              <p></p>
              <div className='exclamation2'>!</div>
                 CAUTION, Do not turn off.
              <div id='line-cascates2'></div>
            </div>
          </div>
        </div>
      </div>

      <div id="downloading">
        <div className="scene">
          <div className="objects">
            <div className="square"></div>
            <div className="circle"></div>
            <div className="triangle"></div>
          </div>
          <div className="wizard">
            <div className="body"></div>
            <div className="right-arm">
              <div className="right-hand"></div>
            </div>
            <div className="left-arm">
              <div className="left-hand"></div>
            </div>
            <div className="head">
              <div className="beard"></div>
              <div className="face">
                <div className="adds"></div>
              </div>
              <div className="hat">
                <div className="hat-of-the-hat"></div>
                <div className="four-point-star --first"></div>
                <div className="four-point-star --second"></div>
                <div className="four-point-star --third"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="progress">
          <div className='progress2'></div>
        </div>
        <div className="noise"></div>
      </div>

      {/** */}

      <div id="panel-start">
        <div id="panel-start-header"></div>
        <div id="panel-start-options">
          <div className="service_box" id="start-low">
            <div className="service_icon">
              <i className="fas fa-star"></i>
            </div>
            <h3 className='lang' data-en="Low Quality" data-pt="Baixa qualidade" data-es="Baja calidad">Low Quality</h3>
            <p className='lang' data-en="For machines with weak configurations, the Simulator will start in basic mode, more focused on performance than graphics." data-pt="Para máquinas com configurações fracas, o Simulador iniciará no modo básico, mais focado em desempenho do que em gráficos." data-es="Para máquinas con configuraciones débiles, el Simulador se iniciará en modo básico, más centrado en el rendimiento que en los gráficos.">For machines with weak configurations, the Simulator will start in basic mode, more focused on performance than graphics.</p>
          </div>
          <div className="service_box" id="start-medium">
            <div className="service_icon">
              <i className="fas fa-star"></i>
            </div>
            <h3 className='lang' data-en="Medium Quality" data-pt="Qualidade média" data-es="Calidad media">Medium Quality</h3>
            <p className='lang' data-en="For machines with medium configurations, the Simulator will start with average graphics, which may affect performance depending on the machine." data-pt="Para máquinas com configurações médias, o Simulador iniciará com gráficos medianos, o que pode afetar o desempenho dependendo da máquina." data-es="Para máquinas con configuraciones medias, el Simulador comenzará con gráficos promedio, lo que puede afectar el rendimiento según la máquina.">For machines with medium configurations, the Simulator will start with average graphics, which may affect performance depending on the machine.</p>
          </div>
          <div className="service_box" id="start-high">
            <div className="service_icon">
              <i className="fas fa-star"></i>
            </div>
            <h3 className='lang' data-en="High Quality" data-pt="Alta qualidade" data-es="Alta calidad">High Quality</h3>
            <p className='lang' data-en="For machines with high configurations, the Simulator will start with graphics at maximum, which may affect performance depending on the machine." data-pt="Para máquinas com configurações altas, o Simulador iniciará com gráficos no máximo, o que poderá afetar o desempenho dependendo da máquina." data-es="Para máquinas con configuraciones altas, el Simulador comenzará con los gráficos al máximo, lo que puede afectar el rendimiento según la máquina.">For machines with high configurations, the Simulator will start with graphics at maximum, which may affect performance depending on the machine.</p>
          </div>
          <div className="service_box" id="start-manage">
            <div className="service_icon">
              <i className="fas fa-cog"></i>
            </div>
            <h3 className='lang' data-en="Manage" data-pt="Gerenciar" data-es="Administrar">Manage</h3>
            <p className='lang' data-en="Open project management screen without needing to load the 3d model." data-pt="Abre a tela de gerenciamento de projetos sem precisar carregar o modelo 3D." data-es="Abre la pantalla de gestión de proyectos sin necesidad de cargar el modelo 3D.">Open project management screen without needing to load the 3d model.</p>
          </div>
        </div>
        <div className="drag-area" id="drag-area">
          <div className="icon"><i className="fas fa-cloud-upload-alt"></i></div>
          <div id="file-header" className='header lang' data-en="Drag & Drop to Upload File" data-pt="Arraste e solte para fazer upload do arquivo" data-es="Arrastrar y soltar para cargar el archivo">Drag & Drop to Upload File</div>
          <span className='lang' data-en="OR" data-pt="OU" data-es="O">OR</span>
          <button id="start-svg-upload" className='lang' data-en="Browse File" data-pt="Procurar arquivo" data-es="Buscar Archivo">Browse File</button>
          <input multiple type="file" hidden accept='image/svg+xml, image/jpeg, image/png' id="file"></input>
        </div>

        <div id="panel-start-upload">
            <div id="panel-start-upload-modal">
              <div id="panel-start-upload-options">

                <div className="option-start-upload">
                  <span className="lang" data-en="QUALITY:" data-pt="QUALIDADE:" data-es="CALIDAD:">QUALITY:</span>
                  <div className="input-select aa">
                    <div className="select select-new" id='select-quality' data-id="list-quality">
                      <div data-id="0" className="active-option" id="active-option-quality">LOW</div>
                      <div className="list" id='list-quality'>
                        <div className="option option-quality option-select lang" data-type="quality" data-en="Low" data-pt="Baixa" data-es="Baja" data-id="0">LOW</div>
                        <div className="option option-quality option-select lang" data-type="quality" data-en="Medium" data-pt="Média" data-es="Medio" data-id="1">MEDIUM</div>
                        <div className="option option-quality option-select lang" data-type="quality" data-en="High" data-pt="Alta" data-es="Alta" data-id="2">HIGH</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="option-start-background"></div>

              </div>

              <div id="panel-start-upload-buttons">
                <button id="start-init" className='lang' data-en="START" data-pt="COMEÇAR" data-es="COMENZAR">VIEW</button>
                <button id="download-all-auto" className='lang' data-en="DOWNLOAD ALL" data-pt="BAIXAR TODOS" data-es="DESCARGAR TODO">DOWNLOAD ALL</button>
                <button id="start-cancel" className='lang' data-en="CANCEL" data-pt="CANCELAR" data-es="CANCELAR">CANCEL</button>
              </div>
            </div>
        </div>
      </div>
    </div>

    
  )
}

export default Simulator
